import { boot } from 'quasar/wrappers'
import axios from 'axios'
let uns, api

uns = axios.create({ baseURL: process.env.UNS_API })
api = axios.create({ baseURL: process.env.PROJECTS_API })

export default boot(({ app }) => {
  app.config.globalProperties.$axios = axios
  app.config.globalProperties.$uns = uns
  app.config.globalProperties.$api = api
})

export { axios, uns, api }
