const routes = [
	{
		path: "",
		component: () => import("layouts/MainLayout.vue"),
		children: [
			{ path: "", component: () => import("pages/IndexPage.vue") },
			{ path: "home", component: () => import("pages/HomePage.vue") },
			{
				path: "contractors",
				component: () => import("pages/ContractorsPage.vue"),
			},
			{
				path: "contractors/:contractorName",
				component: () => import("pages/ContractorPage.vue"),
			},
			{ path: "jobs", component: () => import("pages/JobsPage.vue") },
			{
				path: "jobs/:jobId",
				component: () => import("pages/JobDetailsPage.vue"),
			},
		],
	},

	// Always leave this as last one,
	// but you can also remove it
	{
		path: "/:catchAll(.*)*",
		component: () => import("pages/ErrorNotFound.vue"),
	},
];

export default routes;
